

  $container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xxl: 1400px
  );
  
  /* import */
  
  @import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
  @import "~bootstrap/scss/bootstrap.scss";

    /** paleta de colores **/
    :root {
      --bs-primary-rgb: 117,105, 172;
      --secondary-color: #e74c3c;
      --step-bg: #343e49
    }
    /** paleta de colores **/
    :root {
      --bs-primary-rgb: 117,105, 172;
      --secondary-color: #e74c3c;
      --step-bg: #343e49
    }
  /* framework */
  
  body, html, div, p, span, h1, h2, h3, h4, h5, h6, address, article, aside, blockquote, details, dialog, dd, dl, dt, fieldset, figcaption, 
  figure, footer, form, header, hr, legend, li, main, nav, ol, section, summary, table, tbody, td, tfoot, th, thead, tr, ul, button, a{
    font-family: "Lato", sans-serif !important;
  }


  .bg-body{background-color: #171631 !important;}


  .bg-body{background-color: #171631 !important;}
  .container-fluid{
    padding:0
  }
  .bg-title{background: rgb(117,106,173); background: linear-gradient(90deg, rgba(117,106,173,1) 0%, rgba(23,22,49,1) 50%);}
  .bg-title-80{background: rgb(117,106,173); background: linear-gradient(90deg, rgba(117,106,173,1) 0%, rgba(23,22,49,1) 80%);}
  .bg-title{background: rgb(117,106,173); background: linear-gradient(90deg, rgba(117,106,173,1) 0%, rgba(23,22,49,1) 50%);}
  .bg-title-80{background: rgb(117,106,173); background: linear-gradient(90deg, rgba(117,106,173,1) 0%, rgba(23,22,49,1) 80%);}
  .MuiStepLabel-root svg{
    border: 2px solid var(--step-bg);
    color: #fff;
    border-radius: 100%;
    font-weight: bold;
  }
  .MuiStepLabel-root svg text{
    fill: var(--step-bg);
    font-size: 0.95rem;
  }
  .MuiStepLabel-root svg.Mui-completed{
    background: #333;
  }
  .MuiStepLabel-root svg.Mui-completed path{
    fill: #6729d6;
  }
  .MuiStepLabel-root svg.Mui-active{
    color: var(--step-bg);
    border: 0
  }
  .MuiStepLabel-root svg.Mui-active text{
    fill: #fff;
    font-size: 0.95rem;
  }
  .device-box{
    box-shadow: none;
    border-radius: 30px !important;
    background: rgb(117,106,173) !important; 
    // background: linear-gradient(90deg, rgba(117,106,173,1) 0%, rgba(23,22,49,1) 90%);
    padding: 2px !important;
  }
  .device-box:hover {
    background: rgb(167 160 250) !important;
  }
  .device-box .MuiCardContent-root{
    border-radius: 30px !important;
  }
  
  .MuiInputBase-formControl{
    padding-right: 0 !important;
  }
  .btn-back.Mui-disabled{
    color: rgba(0, 0, 0, 0.26) !important;
  }
  .btn-back{
    color: var(--primary-color) !important;
  }
  .btn-back.MuiButton-outlinedPrimary{
    border-color: var(--step-bg)
  }
  .btn-nextfinish.Mui-disabled {
    background-color: rgba(0, 0, 0, 0.12) !important;
  }
  .btn-back.MuiButton-outlinedPrimary:hover{
    border-color: #ababab;
    background: #fff
  }
  .footer {
    padding: 20px;
  }
  .mh-100vh{min-height: 100vh;}
  .deco-1{max-width: 600px;position : absolute;right: -250px;top: -200px;}
  .deco-2{max-width: 600px;position : absolute;left: -250px;bottom: -180px;transform: rotate(20deg);}
  .deco-3{position: absolute;top:-130px;left: -100px;filter: blur(38.5px);width: 400px;height: 500px;background: rgb(117,106,173);  background: radial-gradient(circle, rgba(117,106,173,1) 0%, rgba(73,58,90,1) 30%, rgba(23,22,49,1) 100%);}
  .deco-4{position: absolute;bottom:-130px;right: -100px;filter: blur(38.5px);width: 400px;height: 500px;background: rgb(117,106,173);  background: radial-gradient(circle, rgba(117,106,173,1) 0%, rgba(73,58,90,1) 30%, rgba(23,22,49,1) 100%);}
  .selectCustom{background: rgb(117,106,173); background: linear-gradient(90deg, rgba(117,106,173,1) 0%, rgba(23,22,49,1) 90%);border-radius: 25px;}
